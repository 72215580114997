@import '~@angular/material/theming';

@include mat-core();

/* For use in src/lib/core/theming/_palette.scss */
$sandoku-primary: (
  50 : #eaf9fa,
  100 : #eaf9fa,
  200 : #eaf9fa,
  300 : #20a5a5,
  400 : #20a5a5,
  500 : #20a5a5,
  600 : #20a5a5,
  700 : #028484,
  800 : #028484,
  900 : #028484,
  A100 : #5bbeff,
  A200 : #5bbeff,
  A400 : #1973b8,
  A700 : #043263,
  contrast: (
    50 : #000000,
    100 : #000000,
    200 : #000000,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #000000,
    A200 : #000000,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$sandoku-accent: (
  50 : #3b8cad,
  100 : #3b8cad,
  200 : #3b8cad,
  300 : #3b8cad,
  400 : #3b8cad,
  500 : #3b8cad,
  600 : #3b8cad,
  700 : #3b8cad,
  800 : #3b8cad,
  900 : #3b8cad,
  A100 : #3b8cad,
  A200 : #3b8cad,
  A400 : #3b8cad,
  A700 : #3b8cad,
  contrast: (
    50 : #000,
    100 : #000,
    200 : #000,
    300 : #000,
    400 : #000,
    500 : #000,
    600 : #000,
    700 : #000,
    800 : #000,
    900 : #000,
    A100 : #000,
    A200 : #000,
    A400 : #000,
    A700 : #000,
  )
);

$sandoku-warn: (
  50 : #ec0000,
  100 : #ec0000,
  200 : #ec0000,
  300 : #ec0000,
  400 : #ec0000,
  500 : #CC0000,
  600 : #CC0000,
  700 : #CC0000,
  800 : #CC0000,
  900 : #CC0000,
  A100 : #990000,
  A200 : #990000,
  A400 : #990000,
  A700 : #990000,
  contrast: (
    50 : #ffffff,
    100 : #ffffff,
    200 : #ffffff,
    300 : #ffffff,
    400 : #ffffff,
    500 : #ffffff,
    600 : #ffffff,
    700 : #ffffff,
    800 : #ffffff,
    900 : #ffffff,
    A100 : #ffffff,
    A200 : #ffffff,
    A400 : #ffffff,
    A700 : #ffffff,
  )
);

$primary: mat-palette($sandoku-primary, 800, 300, 900);
$info: mat-palette($mat-green);
$warn: mat-palette($sandoku-warn, 500, 300, 900);
$accent: mat-palette($sandoku-accent, 300, 50, A700);

$sandoku-theme: mat-light-theme($primary, $accent, $warn);

@include angular-material-theme($sandoku-theme);
