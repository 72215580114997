/* You can add global styles to this file, and also import other style files */
@import './gesto_theme.scss';
@font-face {
  font-family: 'VA-Bold';
  src: url('assets/va-fonts/VA-Bold.woff2');
}

@font-face {
  font-family: 'VA-Medium';
  src: url('assets/va-fonts/VA-Medium.woff2');
}

@font-face {
  font-family: 'VA-Book';
  src: url('assets/va-fonts/VA-Book.woff2');
}

:root {
  --va-navy: #072146;
  --va-core-blue: #006d79;
  --va-core-dark-blue: #043263;
  --va-core-light-blue: #4be9fb;
  --va-medium-blue: #00e1fA;
  --va-medium-light-blue: #49a5e6;
  --va-light-blue: #5bbeff;
  --va-sky-blue: #5bbeff;
  --va-sky-light-blue: #d4edfc;
  --va-aqua: #2dcccd;
  --va-aqua-dark: #25727a;
  --va-aqua-medium: #00b3c7;
  --va-aqua-light: #5ac4c4;
  --va-aqua-white: #eaf9fa;
  --va-600: #121212;
  --va-500: #666666;
  --va-400: #d4d4d4;
  --va-300: #d3d3d3;
  --va-200: #e9e9e9;
  --va-100: #f4f4f4;
  --va-white: #ffffff;
  --black-squeeze: #f5f9fb;
  --va-red: #da3851;
  --va-red-light: #ff3857;
  --va-coral: #f35e61;
  --va-coral-white: #fcdfdf;
  --va-green: #48ae64;
  --va-green-dark: #277a3e;
  --va-green-white: #d9efe0;
  --va-yellow: #f8cd51;
  --va-sand: #d8be75;
  --va-sand-white: #f3ebd5;
  --va-orange: #f7893b;
  --va-pink: #f78be8;
  --va-purple: #8f7ae5;
  --va-grey: #575757;
  --link-color: #1973b8;
  --risklevel-container-border-color: #bdbdbd;
  --va-jagged-ice: #ceede7;
  --size-title: 36px;
}

html {
  margin: 0;
  font-family: VA-Book, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  min-height: 100%;
  width: 100vw;
  max-width: 100%;
  text-size-adjust: 100%;
  scroll-behavior: smooth;
  overflow-x: hidden;
  -webkit-text-size-adjust: 100%;
}

body {
  margin: 0;
  font-family: VA-Book, Helvetica, Arial, sans-serif;
  line-height: 24px;
  color: var(--body-color, #121212);
  background-color: var(--va-core-blue);
  font-size: 100%;
  min-height: 100vh;
  overflow-wrap: break-word;
  position: relative;
  overflow-x: hidden;
  -webkit-font-smoothing: antialiased;
  -webkit-tap-highlight-color: transparent;
  hyphens: none;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  font: inherit;
}

h1 {
  color: var(--va-grey);
  font-size: var(--size-title);
  letter-spacing: 0;
  line-height: 51px;
  border-bottom: 1px solid var(--va-300);
  width: 100%;
  margin: 0;
}

header {
  display: flex;
  justify-content: space-between;

  column-gap: 36px;
  margin-bottom: 40px;
}

.cdk-overlay-pane {
  width: 400px;
}

.header-modal {
  margin: 0;
  border-bottom: 1px solid var(--va-300);
  align-items: center;
  margin-bottom: 24px;
  padding-bottom: 6px;
  h1 {
    border: none;
    line-height: 0;
    font-size: 24px;
  }
}

.filter-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 20px 36px;
}

.filter-footer {
  margin-top: 32px;
  display: flex;
  justify-content: flex-end;
  column-gap: 18px;
}

.mat-form-field-appearance-fill:not(.mat-form-field-disabled)
  .mat-form-field-flex:hover
  ~ .mat-form-field-underline
  .mat-form-field-ripple {
  background-color: var(--va-aqua-dark);
}

.mat-form-field-appearance-fill .mat-form-field-underline::before {
  background-color: var(--va-aqua-dark);
  padding-top: 1px;
}

.mat-form-field-appearance-fill .mat-form-field-flex {
  background-color: var(--black-squeeze);
  border: 1px solid var(--va-aqua-dark);
  height: 58px;
}

.mat-form-field-appearance-fill.mat-form-field-disabled .mat-form-field-flex {
  border: none;
}

.mat-form-field-appearance-outline.mat-focused {
  color: white;
  background-color: transparent;
}

.mat-form-field.mat-form-field-type-mat-date-range-input:focus-within
  .mat-form-field-wrapper
  .mat-form-field-underline {
  border-bottom: 3px solid var(--va-aqua-dark);
  box-shadow: 2px 2px 2px 1px rgb(2 132 132 / 45%);
}

.button-bg-transition, .delete-button-bg-transition {
  display: flex;
  align-items: center;
  column-gap: 12px;
  border: none;
  font-size: 16px;
  padding: 14px 24px;
  color: var(--va-white);
  max-height: 52px;
  white-space: nowrap;
  font-family: VA-Medium, Helvetica, Arial, sans-serif;
  will-change: background-position;
  background-size: 210% 100%;
  background-position: 99% center;
  background-repeat: no-repeat;
  transition: background-position 0.66667s cubic-bezier(0.24, 0.22, 0.31, 1.07);
  background-color: var(--va-aqua-dark);
  background-image: linear-gradient(
    100deg,
    var(--va-aqua-medium) 50%,
    var(--va-aqua-dark) 50%
  );
  cursor: pointer;
}

.delete-button-bg-transition {
  background-color: var(--va-red);
  background-image: linear-gradient(
    100deg,
    var(--va-red-light) 50%,
    var(--va-red) 50%
  );
}

.button-bg-transition:hover, .delete-button-bg-transition:hover {
  background-position: 0 center;
}

.button-bg-transition:disabled {
  background-color: var(--va-400);
  background-image: none;
  cursor: not-allowed;
}

table {
  table-layout: fixed;
  border-spacing: 0;
  width: 100%;
  padding: 2px 24px;
  background-color: var(--va-white);
  border-radius: 12px;
  margin-bottom: 24px;
}

th {
  font-size: 16px;
}

th,
td {
  text-align: start;
  padding: 10px;
  border-top: none;
  border-bottom: 1px solid var(--va-jagged-ice);
}

td:first-of-type,
th:first-of-type {
  padding: 20px 0;
}

td {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

// tooltip visible into table
td.tooltip-table {
  overflow: visible;
}
// end tooltip visible into table

tbody tr:last-of-type td {
  border-bottom: none;
}

input[type='text']:focus,
input[type='number']:focus {
  border-bottom: 4px solid var(--va-aqua-dark);
  box-shadow: 2px 2px 2px 1px rgb(2 132 132 / 45%);
}

input.input-error:focus {
  border-bottom: 3px solid red;
  box-shadow: 2px 2px 2px 1px rgb(255 0 0 / 45%);
}

input[type='text']:focus.mat-date-range-input-inner {
  border-bottom: none;
  box-shadow: none;
}

.mat-form-field-type-mat-date-range-input .mat-form-field-infix {
  padding: 8px 0;
  border-top: 0;
}
